<template>
  <v-app-bar
    id="core-app-bar"
    app
    height="72"
    :shrink-on-scroll="responsive"
    clipped-left
    max-width="100%"
  >
    <!-- <v-list-item
      style="background: #fff;"
      class="elevation-3"
    > -->
    <!-- </v-list-item> -->

    <v-app-bar-nav-icon
      v-if="responsive || !$store.state.app.drawer"
      class="align-self-center"
      @click.stop="onClick"
    />

    <v-img
      v-if="!responsive"
      :src="logo"
      :contain="true"
      :height="29"
      :max-width="responsive || ($auth.user()?.client) ? 35 : 189"
      position="left"
    />

    <v-divider
      v-if="$auth.user()?.client"
      vertical
      inset
      class="ml-2 me-3"
    />

    <v-img
      v-if="$auth.user()?.client"
      :src="responsive ? $auth.user().client.icon : $auth.user().client.logo"
      :height="responsive ? 36 : 40"
      :max-width="responsive ? 36 : 120"
      contain
      class="align-self-center xmb-3 me-3"
    />

    <v-divider
      vertical
      inset
      class="me-4"
    />

    <v-toolbar-title
      class="tertiary--text font-weight-light align-self-center mt-1"
    >
      <!-- <v-btn
        v-if="responsive"
        dark
        icon
        @click.stop="onClick"
      >
        <v-icon>mdi-view-list</v-icon>
      </v-btn> -->
      {{ title }}
    </v-toolbar-title>

    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
    >
      <template #activator="{ on }">
        <v-btn
          small
          ripple
          icon
          plain
          class="me-1"
          v-on="on"
        >
          <v-icon
            size="20"
          >
            far fa-question-circle
          </v-icon>
        </v-btn>
      </template>
      <v-list
        class="py-0"
        dense
      >
        <v-dialog
          v-model="videoDialog.tutorials"
          width="80%"
          max-width="800"
        >
          <template #activator="{ on }">
            <v-list-item
              v-on="on"
            >
              <v-list-item-action class="me-3">
                <v-icon
                  key="fal fa-file-check fa-fw"
                  small
                >
                  fal fa-clapperboard-play fa-fw
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Video Tutorials
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-card>
            <v-card-title>
              Video Tutorials
              <v-spacer />
              <v-btn
                icon
                tile
                color="grey"
                @click.stop="videoDialog.tutorials = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <support-solutions
              :folder="67000576721"
            />
          </v-card>
        </v-dialog>
        <v-list-item
          href="https://support.arkstone.ai"
          target="_blank"
        >
          <v-list-item-action class="me-3">
            <v-icon
              key="fal fa-file-check fa-fw"
              small
            >
              fal fa-book-open-cover fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Knowledge Base
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          href="mailto:support@arkstone.ai"
        >
          <v-list-item-action class="me-3">
            <v-icon
              key="fal fa-file-check fa-fw"
              small
            >
              fal fa-messages-question fa-fw
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Email Support
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-row
        align="center"
        class="mx-0"
      >
        <v-text-field
          class="me-4 purple-input"
          color="purple"
          label="Search..."
          hide-details
        />

        <v-btn
          icon
          to="/"
        >
          <v-icon color="tertiary">
            mdi-view-dashboard
          </v-icon>
        </v-btn>

        <v-btn
          to="/user-profile"
          icon
        >
          <v-icon color="tertiary">
            mdi-account
          </v-icon>
        </v-btn>
      </v-row> -->
    <user-menu />
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";

export default {
  components: {
    SupportSolutions: () => import('@/components/SupportSolutions.vue'),
    UserMenu: () => import('@/components/UserMenu.vue'),
  },
  data: () => ({
    logo_img: "/img/arkstone-logo-name.svg",
    icon_img: "/img/arkstone-logo.svg",
    responsive: false,
    videoDialog: {
      tutorials: false,
      explainers: false,
    },
  }),

  computed: {
    title() {
      return this.$options.filters.capitalize(this.$tc(this.$route?.meta?.title, 2)) || this.$t(this.$route.name);
    },
    logo () {
      return this.$auth.user() && this.$auth.user().client ? this.icon_img : this.logo_img
    },
  },

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
};
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
